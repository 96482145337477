import { render, staticRenderFns } from "./aliConfig.vue?vue&type=template&id=4d847b98"
import script from "./aliConfig.vue?vue&type=script&lang=js"
export * from "./aliConfig.vue?vue&type=script&lang=js"
import style0 from "./aliConfig.vue?vue&type=style&index=0&id=4d847b98&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-co_gj3r3yykdrk7kkdeno2phvnk7q/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports